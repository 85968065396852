<template>
  <div>
    <el-input size="small" style="width: 180px; margin-bottom: 10px;margin-top: 10px;" v-model="input" @keyup.enter.native="search" :placeholder="$t('UserManage.adminPage.placeholder')"><i @click="search" slot="suffix" class="el-input__icon el-icon-search" style="cursor: pointer"></i></el-input>
    <div style="height:410px;overflow: auto;" class="bigBox">
      <el-table :cell-style="{padding:'3px 0px'}" :header-row-style="{height:'30px'}" size="mini" stripe ref="multipleTable" :data="tableData" class="tableClass" tooltip-effect="dark" style="width: 100%">
        <el-table-column :show-overflow-tooltip="true" prop="deviceName" :label="$t('public.DeviceName')"  width="150">
        </el-table-column>
        <el-table-column prop="osId" :label="$t('public.OperatingSystem')" width="100">
          <template v-slot="{ row }">
              <template v-if="row.osId == 10"> iOS </template>
              <template v-if="row.osId == 20"> Android </template>
              <template v-if="row.osId == 30"> Windows </template> 
              <template v-if="row.osId == 40"> Linux </template> 
          </template>
        </el-table-column>
        <el-table-column prop="osId" :label="$t('UserManage.adminPage.DeviceStatus')" width="100">
          <template v-slot="{ row }">
            <!-- <template v-if="row.status == 5">
              新建
              <span style="color: #575656">{{ $t("UserManage.adminPage.newBuilt") }}</span>
            </template> -->
            <!-- <template v-if="row.status == 15">
              加入
              <span style="color: #2ba245">{{ $t("UserManage.adminPage.join") }}</span>
            </template> -->
            <template v-if="row.status == 10">
              <!-- 等待注册 -->
              <span style="color: #575656">{{ $t("UserManage.adminPage.WaitingRegistra") }}</span>
            </template>
            <!-- 6.1.1版本 -->
            <!-- 未激活 -->
            <template v-if="row.status == 5">
              <span style="color: #2ba245">{{$t('UserManage.adminPage.Notactive')}}</span>
            </template>
            <!-- 已激活 -->
            <template v-if="row.status == 15">
              <span style="color: #575656">{{$t('UserManage.adminPage.Active')}}</span>
            </template>
            <template v-if="row.status == 20">
              <!-- 取消激活 -->
              <span style="color: #1e8ae5">{{ $t("UserManage.adminPage.deactivete") }}</span>
            </template>
            <template v-if="row.status == 25">
              <!-- 注销 -->
              <span style="color: #1e8ae5">{{ $t("UserManage.adminPage.logOff") }}</span>
            </template>
            <template v-if="row.status == 35">
              <!-- 停用 -->
              <span style="color: #ff0000">{{ $t("public.Outofservice") }}</span>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="bindStatus" :label="$t('UserManage.adminPage.BindingState')" width="100">
          <template v-slot="{ row }"> 
            <template v-if="row.bindStatus == 0">
              <!-- 解绑 -->
              <span style="color: #575656">{{ $t("UserManage.adminPage.Unbinding") }}</span>
            </template>
            <template v-if="row.bindStatus == 1">
              <!-- 绑定 -->
              <span style="color: #2ba245">{{ $t("UserManage.adminPage.binding") }}</span>
            </template> 
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" prop="enrollmentTime" :label="$t('UserManage.adminPage.BindLastLoginTime')">
          <template v-slot="{ row }">
            <span v-if="row.deviceConIMStatus.lastLoginTime == null"></span>
            <span v-else>{{ row.deviceConIMStatus.lastLoginTime }}</span>
          </template>
        </el-table-column>
      </el-table>
      <p style="margin-top: 10px">
        {{ $t("public.total") }}<b>{{ total }}</b>{{ $t("UserManage.adminPage.Records") }}
      </p>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    rowId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      input: '', //查询输入框
      tableData: [], //表格数据
      total: 0 //数据数量
    }
  },
  created() {
    this.getListData()
  },
  mounted() {},
  methods: {
    //获取列表
    async getListData() {
      var param = {
        ownerId: this.rowId
      }
      console.log(param, 'param获取列表')
      const res = await this.$axios.post(
        '/httpServe/device/selectListByOwnerId',
        param,
        true
      )
      console.log(res, 'res')
      this.tableData = res.data.content
      this.total = res.data.content.length
    },
    //查询
    search() {
      if (this.input.length > 0) {
        let arr = this.tableData
        let list = arr.filter(
          (item) =>
            item.deviceName.toLowerCase().indexOf(this.input.toLowerCase()) !==
            -1
        )
        this.tableData = list
        this.total = list.length
      } else {
        this.getListData()
      }
      // if (!this.input) {
      //   this.getListData(this.deviceCount)
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
