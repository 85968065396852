<template>
  <div>
    <!-- 管理员面包屑 -->
    <el-row class="formBox" v-if="flag == 1">
      <el-row :gutter="4">
        <el-col :span="2">
          <el-select
            clear="selectCom"
            size="small"
            v-model="optionsValue"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="$t(`UserManage.adminPage.${item.label}`)"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <select-input
            :options="options1"
            @selectSearchChange="selectSearchChange"
            @searchList="search"
          ></select-input>
        </el-col>
      </el-row>
      <!-- // 1、单选是正常的用户--停用高亮--启用跟解锁置灰；停用的用户--启用高亮，停用跟解锁置灰；锁定的用户--解锁高亮，停用跟启用置灰 -->
      <!-- // 2、批量选择全部高亮，、，能执行的执行，不能执行的不执行（启用-只针对停用；锁定和正常都可以停用） -->
      <el-button-group class="elRow">
        <!-- //新增 -->
        <el-button type="primary" size="small" @click="addNew">{{
          $t("public.New")
        }}</el-button>
        <!-- 设置为普通用户 -->
        <el-button
          :type="button_comType"
          :disabled="comFlag"
          size="small"
          @click="toUser(delArr)"
          >{{ $t("UserManage.adminPage.SetAsRegularUser") }}</el-button
        >
        <!-- 停用 -->
        <el-button
          :type="button_stopType"
          :disabled="stopFlag"
          size="small"
          @click="toStop(delArr)"
          >{{ $t("public.Outofservice") }}</el-button
        >
        <!-- 启用 -->
        <el-button
          :type="button_startType"
          :disabled="startFlag"
          size="small"
          @click="toEnable(delArr)"
          >{{ $t("public.Enable") }}</el-button
        >
        <!-- 解锁 -->
        <el-button
          :type="button_unlockType"
          :disabled="unlockFlag"
          size="small"
          @click="unlockBtn(delArr)"
          >{{ $t("public.Unlock") }}</el-button
        >
        <!-- 导出 -->
        <el-button :type="button_derive" size="small" @click="derive(delArr)">{{
          $t("public.Derive")
        }}</el-button>
        <!-- 删除 -->
        <el-button
          :type="button_delType"
          :disabled="delFlag"
          size="small"
          @click="delMuch(delArr)"
          >{{ $t("public.Delete") }}</el-button
        >
      </el-button-group>
      <el-row :gutter="4" class="elRow">
        <el-table
          fit
          stripe
          size=" mini"
          ref="multipleTable"
          :data="tableData"
          class="tableClass"
          tooltip-effect="dark"
          style="width: 100%; font-size: 12px"
          @select="checkChange"
          @sort-change="sort"
          @select-all="selectAll"
          @selection-change="handleSelectionChange"
          :row-key="getRowKey"
          :default-sort="{ prop: 'u.updateTime', order: 'descending' }"
          :row-style="iRowStyle"
          :cell-style="iCellStyle"
          :header-row-style="iHeaderRowStyle"
          :header-cell-style="{
            background: '#f7f8fa',
            color: '#606266',
            padding: '0px',
          }"
        >
          <el-table-column
            type="selection"
            align="center"
            width="60"
            :selectable="selectEnable"
            :reserve-selection="true"
          >
          </el-table-column>
          <el-table-column
            prop="u.loginName"
            :label="$t('public.Username')"
            :show-overflow-tooltip="true"
            width="120"
            sortable="custom"
          >
            <template v-slot="{ row }">
              {{ row.loginName }}
            </template>
          </el-table-column>
          <el-table-column
            prop="u.userName"
            :label="$t('public.Name')"
            :show-overflow-tooltip="true"
            width="120"
            sortable="custom"
          >
            <template v-slot="{ row }">
              {{ row.userName }}
            </template>
          </el-table-column>

          <el-table-column
            prop="u.userSex"
            :label="$t('UserManage.adminPage.gender')"
            :show-overflow-tooltip="true"
            width="80"
            sortable="custom"
          >
            <template v-slot="{ row }">
              <span v-show="row.userSex == 0">{{
                $t("UserManage.adminPage.male")
              }}</span>
              <span v-show="row.userSex == 1">{{
                $t("UserManage.adminPage.female")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="u.userRole"
            :label="$t('UserManage.adminPage.role')"
            :show-overflow-tooltip="true"
            width="120"
            sortable="custom"
          >
            <template v-slot="{ row }">
              {{ row.role.name }}
            </template>
          </el-table-column>
          <el-table-column
            prop="g.groupFullName"
            :label="$t('public.Section')"
            :show-overflow-tooltip="true"
            width="120"
            sortable="custom"
          >
            <template v-slot="{ row }">
              <span v-if="row.groupFullName">{{
                row.groupFullName.substr(groupFullNameShowByIndex)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="gg.groupFullName"
            :label="$t('UserManage.adminPage.ManageDepartment')"
            :show-overflow-tooltip="true"
            width="120"
            sortable="custom"
          >
            <template v-slot="{ row }">
              <span v-if="row.manageGroupFullName">{{
                row.manageGroupFullName.substring(groupFullNameShowByIndex)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="userGroupName"
            sortable="custom"
            :show-overflow-tooltip="true"
            :label="$t('Delivery.DistributionHistory.UserGroup')"
            width="100"
          >
            <template v-slot="{ row }">
              <span v-if="row.userGroupName">
                {{ row.userGroupName }}
              </span>
              <span v-else>/</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="u.phone"
            :label="$t('public.Phone')"
            :show-overflow-tooltip="true"
            width="120"
            sortable="custom"
          >
            <template v-slot="{ row }">
              {{ row.phone | PhoneFilter }}
            </template>
          </el-table-column>
          <el-table-column
            prop="u.email"
            :label="$t('public.Email')"
            width="120"
            sortable="custom"
          >
            <template v-slot="{ row }">
              {{ row.email | hideMail }}
            </template>
          </el-table-column>
          <el-table-column
            prop="u.deviceCount"
            :label="$t('UserManage.adminPage.AssociatedDevices')"
            :show-overflow-tooltip="true"
            width="120"
            sortable="custom"
          >
            <template v-slot="{ row }">
              <span
                v-if="row.deviceCount >= 1"
                @click="deviceInfo(row)"
                style="cursor: pointer; color: #d38a08"
              >
                {{ row.deviceCount }}
              </span>
              <span v-else>
                {{ row.deviceCount }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="u.status"
            :label="$t('UserManage.adminPage.UserStatus')"
            :show-overflow-tooltip="true"
            width="120"
            sortable="custom"
          >
            <template v-slot="{ row }">
              <!-- 正常 -->
              <span v-if="row.status == '1'" style="color: #2ba245">{{
                $t("public.Normal")
              }}</span>
              <!-- 锁定 -->
              <span v-if="row.status == '2'" style="color: orange">{{
                $t("public.Lock")
              }}</span>
              <!-- 停用 -->
              <span v-if="row.status == '3'" style="color: red">{{
                $t("public.Outofservice")
              }}</span>
            </template>
          </el-table-column>

          <el-table-column
            prop="u.updateTime"
            :label="$t('public.ModificationTime')"
            :show-overflow-tooltip="true"
            width="140"
            sortable="custom"
          >
            <template v-slot="{ row }">
              <span v-if="row.updateTime == null"></span>
              <span v-else>{{ row.updateTime }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('public.Controls')"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <span class="action_icon" v-if="row.manageGroupId != 1">
                <!-- style="width: 15px;cursor: pointer; height: 15px; margin-left: 5px" -->
                <img
                  @click="reset_pwd(row)"
                  title="重置密码"
                  src="../../../assets/reset_pwd.png"
                  alt=""
                />
              </span>
              <span
                class="action_icon"
                v-if="
                  row.status == 2 &&
                  row.manageGroupId != 1 &&
                  row.manageGroupId != manageGroupIdStr
                "
              >
                <img
                  :title="$t('public.Unlock')"
                  @click="unlockIncident(row)"
                  src="../../../assets/unlock.png"
                  alt=""
                />
              </span>
              <span class="action_icon" v-if="row.manageGroupId != 1">
                <!-- style="width: 15px; cursor: pointer; height: 15px;margin-left: 5px" -->
                <img
                  @click="edit(row)"
                  :title="$t('public.Editor')"
                  src="../../../assets/icon_write.png"
                  alt=""
                />
              </span>
              <span
                class="action_icon"
                v-if="
                  row.manageGroupId != 1 &&
                  row.manageGroupId != manageGroupIdStr
                "
              >
                <!-- style="width: 15px;cursor: pointer; height: 15px; margin-left: 5px" -->
                <img
                  @click="details(row)"
                  :title="$t('public.Delete')"
                  src="../../../assets/icon_del.png"
                  alt=""
                />
              </span>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页-->
        <pagination
          :page="page.currentPage"
          :limit="page.pageSize"
          :total="page.total"
          :pageSizes="[10, 20, 30, 40]"
          v-on:handleChildGetList="handleParentGetList"
        ></pagination>
      </el-row>
    </el-row>
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
    <!-- //重置密码弹框 -->
    <elDialog
      :title="$t('UserManage.adminPage.resePass')"
      :width="'405px'"
      :num="'144px'"
      :btnTitle="$t('UserManage.adminPage.submit')"
      :dialogVisible="statusVisiblemm"
      @handleClose="handleClose"
      @heightCustom="heightCustom"
      :flagbtn="true"
      :determineBtn="true"
      @determine="determine"
    >
      <el-form
        style="height: 90px; margin-top: 10px"
        :model="form"
        :rules="rules"
        ref="form"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item
          :label="$t('UserManage.adminPage.NewPass')"
          prop="newPassword1"
        >
          <el-input
            type="password"
            v-model="form.newPassword1"
            clearable
            size="mini"
            class="passInputs"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('UserManage.adminPage.ConfirmPass')"
          prop="newPassword2"
        >
          <el-input
            v-model="form.newPassword2"
            clearable
            type="password"
            size="mini"
            class="passInputs"
          ></el-input>
        </el-form-item>
      </el-form>
    </elDialog>

    <elDialog
      :title="$t('UserManage.adminPage.AssociatedDevices')"
      :num="'470px'"
      :dialogVisible="statusVisible"
      v-if="statusVisible"
      @handleClose="handleClose"
      @heightCustom="heightCustom"
    >
      <guanPage :rowId="rowId"></guanPage>
    </elDialog>
    <!-- //新增页面组件 -->
    <add-admin v-if="flag == 2" @addSave="addSaveData"></add-admin>
    <!-- //编辑页面组件 -->
    <edit-admin
      v-if="flag == 3"
      :editRows="editRow"
      @editSave="editSaveData"
    ></edit-admin>
  </div>
</template>

<script>
import selectInput from "@/components/selectInput";
import utils from "../../../utils/index.js";
import elDialog from "@/components/elDialog"; //详情弹框
import guanPage from "../components/guanPage"; //关联页面
import addAdmin from "../components/addAdmin"; //新增页面
import editAdmin from "../components/editAdmin.vue"; //编辑页面
import naviGation from "@/components/hearder/index"; //面包屑组件
import dialogInfo from "@/components/promptMessage/index";
import pagination from "@/components/pagination/page.vue"; //分页组件
export default {
  components: {
    selectInput,
    guanPage,
    addAdmin,
    elDialog,
    editAdmin,
    naviGation,
    pagination,
    dialogInfo,
  },
  data() {
    return {
      page: {
        currentPage: 1, //当前页码
        pageSize: 10, //每页显示条数
        total: 0, //返回数据总条数
        orderRule: "DESC",
        orderColume: "u.updateTime", //排序字段
      },
      form: {
        newPassword1: "",
        newPassword2: "",
      },
      flag: 1, //页面切换标识
      rowId: "", //关联设备的id
      value: "用户名", //下拉框默认值
      input: "", //输入框查询字段
      userId: "",
      id_str: "", //需要修改密码的id
      delArr: [], //存放要删除的数据
      editRow: {}, //编辑数据的id
      options: [
        {
          value: "",
          label: "用户状态",
        },
        {
          value: "1",
          label: "正常",
        },
        {
          value: "2",
          label: "锁定",
        },
        {
          value: "3",
          label: "停用",
        },
      ],
      checked: false, // 全选框双向绑定
      options1: [
        {
          value: "loginName",
          label: "用户名",
        },
        {
          value: "userName",
          label: "姓名",
        },
        {
          value: "phone",
          label: "电话",
        },
        {
          value: "userGroupName",
          label: "用户组",
        },
      ],
      selectVal: {
        deviceName: "",
        InputText: "",
      },
      button_comType: "info",
      button_stopType: "info",
      button_startType: "info",
      button_unlockType: "info",
      button_derive: "primary",
      button_delType: "info",
      comFlag: true,
      startFlag: true,
      stopFlag: true,
      unlockFlag: true,
      delFlag: true,
      userName: "", //需要修改密码的用户名
      devCount: false, //选中管理员是否有关联设备
      titleInfo: "", // 提示框的提示语
      tableData: [], //表格数据
      seleShift: "loginName", // 选中下拉框转译
      placeholder: "用户名",
      isCheckShow: false, // 全选弹框
      optionsValue: "", //下拉框默认值
      visibaelFlag: false, //提示消息默认关闭
      statusVisible: false, //关联设备弹框框默认关闭
      statusVisiblemm: false, //修改密码谈框默认关闭
      manageGroupIdStr: "",
      groupFullNameShowByIndex: "",
      rules: {
        newPassword1: [
          {
            required: true,
            message: this.$t("UserManage.adminPage.newPasswordVoid"),
            trigger: "blur",
          },
          {
            min: 8,
            max: 32,
            message: this.$t("UserManage.adminPage.newPasswordReg"),
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (
                /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)/.test(
                  value
                ) == false
              ) {
                callback(
                  new Error(this.$t("UserManage.adminPage.newPasswordReg"))
                );
              } else if (value == "") {
                callback(
                  new Error(this.$t("UserManage.adminPage.newPasswordVoid"))
                );
              } else {
                callback();
              }
            },
            trigger: "change", //这里还有blur触发方法； 如果设为none，那么只有this.$refs[formName]['validate']时才会验证
          },
        ],
        newPassword2: [
          {
            required: true,
            message: this.$t("UserManage.adminPage.ConfirmPassVoid"),
            trigger: "blur",
          },
          {
            min: 8,
            max: 32,
            message: this.$t("UserManage.adminPage.newPasswordReg"),
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(
                  new Error(this.$t("UserManage.adminPage.ConfirmPassVoid"))
                );
              } else if (
                /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)/.test(
                  value
                ) == false
              ) {
                callback(
                  new Error(this.$t("UserManage.adminPage.newPasswordReg"))
                );
              } else if (
                this.form.newPassword1 !== "" &&
                value !== this.form.newPassword1
              ) {
                callback(
                  new Error(this.$t("UserManage.adminPage.PassRepetition"))
                );
              } else {
                callback();
              }
            },
            trigger: "blur", //这里还有blur触发方法； 如果设为none，那么只有this.$refs[formName]['validate']时才会验证
          },
        ],
      },
    };
  },
  created() {
    this.getTableList(); //获取表格数据
  },
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
    this.manageGroupIdStr = sessionStorage.getItem("manageGroupId");
  },

  methods: {
    // 导出
    async derive() {
      let token2 = sessionStorage.getItem("Authorization")
        ? sessionStorage.getItem("Authorization")
        : "eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE2MzMwMjMzNzUsInVzZXJJZCI6MSwiaWF0IjoxNjMzMDAxNzc1fQ.hSF4bNDHVFZR2h4R0osNBF56ChR_l03mwx9QYaS1E0JDtIFlme3dP8oktCjEIaGi4QzXutLRMmjFWxuDNtaMMA";

      let userString = decodeURIComponent(
        escape(window.atob(token2.split(".")[1]))
      );

      // let userString = decodeURIComponent(
      //   escape(window.atob(this.token.split(".")[1]))
      // );
      this.userId = JSON.parse(userString).userId;

      var param = {
        userId: this.userId,
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule,
        // groupId: '1',
        status: this.optionsValue,
        loginName: this.selectVal.deviceName
          ? this.selectVal.deviceName == "loginName"
            ? this.selectVal.InputText
            : ""
          : "",
        userName: this.selectVal.deviceName
          ? this.selectVal.deviceName == "userName"
            ? this.selectVal.InputText
            : ""
          : "",
        phone: this.selectVal.deviceName
          ? this.selectVal.deviceName == "phone"
            ? this.selectVal.InputText
            : ""
          : "",
      };
      const res = await this.$axios.postExport(
        "/httpManageExport/manage/exportFile",
        param,
        false
      );
      const link = document.createElement("a");
      var fileName = "exportManager_" + Date.parse(new Date()) + ".xls";
      let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", decodeURI(fileName));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    selectSearchChange(val) {
      this.selectVal = val;
    },
    //限制输入特殊字符
    btKeyUp(e) {
      e.target.value = e.target.value
        .replace(
          /[`~!@#$%^&*()_\-+=<>?:"{}|,/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
          ""
        )
        .replace(/\s/g, "");
    },
    getRowKey(row) {
      return row.id;
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    // 高度
    heightCustom() {
      this.height.height = this.num;
    },
    handleClose() {
      this.statusVisible = false; //关联设备弹框
      this.visibaelFlag = false; //提示消息  关闭
      this.statusVisiblemm = false; //修改密码
      this.form = {};
      this.$refs["form"]?.resetFields();
    },
    //确定回调事件
    determine() {
      this.visibaelFlag = false; //提示消息  关闭
      this.resetPass();
    },
    //仅对 type=selection 的列有效，类型为 Function，Function 的返回值用来决定这一行的 CheckBox 是否可以勾选
    selectEnable(row, rowIndex) {
      if (row.groupId == 1 || row.manageGroupId == this.manageGroupIdStr) {
        return false;
      } else {
        return true;
      }
    },
    //下拉框
    changeOption(item) {
      // console.log(item, '下拉框')
      // this.options1.forEach((k) => {
      //   if (item == k.label1) {
      //     this.seleShift = k.value1
      //   }
      // })
      // this.placeholder = item
      // this.value = item
    },
    //排序
    sort(column) {
      console.log(column.order, column.prop, "kklklk");
      if (column.order === "ascending") {
        this.page.orderRule = "ASC";
      } else if (column.order === "descending") {
        this.page.orderRule = "DESC";
      }
      this.page.orderColume = column.prop;
      //调用列表方法
      this.search();
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.page.currentPage = page;
      this.page.pageSize = limit;
      //调用列表方法
      this.getTableList(this.selectVal);
      // this.search()
    },

    //查询
    async search(val) {
      this.page.currentPage = 1
      var params = {
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule,
        status: this.optionsValue,
        loginName: val.deviceName
          ? val.deviceName == "loginName"
            ? val.InputText
            : ""
          : "",
        userName: val.deviceName
          ? val.deviceName == "userName"
            ? val.InputText
            : ""
          : "",
        phone: val.deviceName
          ? val.deviceName == "phone"
            ? val.InputText
            : ""
          : "",
        userGroupName: val.deviceName
          ? val.deviceName == "userGroupName"
            ? val.InputText
            : ""
          : "",
      };
      console.log(params, "管理员列表参数");
      const res = await this.$axios.post(
        "/httpServe/manage/getDataInfo",
        params,
        true
      );
      console.log(res, "表格数据------");
      this.tableData = res.data.content;
      this.tableData.forEach((item) => {
        if (item.phone && item.phone.trim()) {
          item.phone = this.$utils.common.decrypt(item.phone, item.userName);
        }
      });

      this.tableData.forEach((item) => {
        if (item.email && item.email.trim()) {
          item.email = this.$utils.common.decrypt(item.email, item.userName);
        }
      });
      this.page.total = res.data.total;
    },
    //获取表格
    async getTableList(val) {
      console.log(val, "查询用户组");
      this.selectVal = val;
      // alert('this.getTableList()')
      // if (
      //   this.optionsValue == '1' ||
      //   this.optionsValue == '2' ||
      //   this.optionsValue == '3'
      // ) {
      //   this.page.currentPage = 1
      // }
      this.button_comType = "info"; //普通按钮
      this.comFlag = true;
      this.button_stopType = "info"; //停用按钮
      this.stopFlag = true;
      this.button_startType = "info"; //启用按钮
      this.startFlag = true;
      this.button_delType = "info"; //删除按钮
      this.delFlag = true;
      //解锁按钮不可点击 button_unlock
      this.button_unlockType = "info";
      this.unlockFlag = true;
      let token2 = sessionStorage.getItem("Authorization")
        ? sessionStorage.getItem("Authorization")
        : "eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE2MzMwMjMzNzUsInVzZXJJZCI6MSwiaWF0IjoxNjMzMDAxNzc1fQ.hSF4bNDHVFZR2h4R0osNBF56ChR_l03mwx9QYaS1E0JDtIFlme3dP8oktCjEIaGi4QzXutLRMmjFWxuDNtaMMA";
      let userString = decodeURIComponent(
        escape(window.atob(token2.split(".")[1]))
      );
      this.userId = JSON.parse(userString).userId;
      var params = {
        userId: this.userId,
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule,
        // groupId: '1',
        status: this.optionsValue,
        loginName: val
          ? val.deviceName == "loginName"
            ? val.InputText
            : ""
          : "",
        userName: val
          ? val.deviceName == "userName"
            ? val.InputText
            : ""
          : "",
        phone: val ? (val.deviceName == "phone" ? val.InputText : "") : "",
        userGroupName: val
          ? val.deviceName == "userGroupName"
            ? val.InputText
            : ""
          : "",
      };
      console.log(params, "管理员列表参数");
      const res = await this.$axios.post(
        "/httpServe/manage/getDataInfo",
        params,
        true
      );
      console.log(res, "表格数据------");
      this.tableData = res.data.content;
      this.tableData.forEach((item) => {
        if (item.phone && item.phone.trim()) {
          item.phone = this.$utils.common.decrypt(item.phone, item.userName);
        }
      });
      this.tableData.forEach((item) => {
        if (item.email && item.email.trim()) {
          item.email = this.$utils.common.decrypt(item.email, item.userName);
        }
      });
      this.page.total = res.data.total;
    },
    //设置为普通用户
    async toUser(obj) {
      console.log(obj, "设置为普通用户");
      let id = [];
      obj.forEach((item) => {
        id.push(item.id);
      });
      var params = {
        ids: id,
      };
      this.$confirm(
        this.$t("UserManage.adminPage.OrdinaryUsers"),
        this.$t("UserManage.adminPage.DetermineInfo"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(async () => {
          const res = await this.$axios.post(
            "/httpServe/manage/toUser",
            params,
            true
          );

          this.$message({
            message: this.$t("UserManage.adminPage.SuccessfullySetUser"),
            type: "success",
            offset: 100,
          });
          this.getTableList(); //删除成功后调用表格数据
          this.$refs.multipleTable.clearSelection(); //清除之前的选中状态
        })
        .catch(() => {
          return false;
        });
    },
    //设置停用
    toStop(obj) {
      const ids = obj.map((obj) => obj.id);
      var params = { status: "3", ids: ids };
      this.$confirm(
        this.$t("UserManage.adminPage.sureDeactivate"),
        this.$t("UserManage.adminPage.DetermineInfo"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(async () => {
          const res = await this.$axios.post(
            "/httpServe/manage/updateStatus",
            params,
            true
          );
          console.log(res,'啦啦啦');
          if(res.data == 0){
            this.$message({
              message: this.$t("UserManage.adminPage.DeactivateAdminSuccess"),
              type: "success",
              offset: 100,
            });
          }else{
            this.$message({
              message: this.$t("UserManage.adminPage.DeactivateAdminError"),
              type: "error",
              offset: 100,
            });
          }
          
          this.getTableList(this.selectVal); //删除成功后调用表格数据
          this.$refs.multipleTable.clearSelection(); //清除之前的选中状态
        })
        .catch(() => {
          return false;
        });
    },
    //设置启用
    toEnable(obj) {
      const ids = obj.map((obj) => obj.id);
      var params = { status: "5", ids: ids };
      this.$confirm(
        this.$t("UserManage.adminPage.sureEnable"),
        this.$t("UserManage.adminPage.DetermineInfo"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(async () => {
          const res = await this.$axios.post(
            "/httpServe/manage/updateStatus",
            params,
            true
          );
          if(res.data == 0 ){
            this.$message({
              message: this.$t("UserManage.adminPage.enabledAdminSuccess"),
              type: "success",
              offset: 100,
            });
          }else{
            this.$message({
              message: this.$t("UserManage.adminPage.enabledAdminError"),
              type: "error",
              offset: 100,
            });
          }
          
          this.getTableList(this.selectVal); //删除成功后调用表格数据
          this.$refs.multipleTable.clearSelection(); //清除之前的选中状态
        })
        .catch(() => {
          return false;
        });
    },
    //确定重置密码验证
    resetPass(form) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          //验证通过保存数据
          this.confirmPass();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    //reset_pwd重置按钮
    reset_pwd(row) {
      this.statusVisiblemm = true;
      this.userName = row.loginName;
      this.id_str = row.id;
    },
    //点击确定修改密码
    async confirmPass() {
      const res = await this.$axios
        .post(
          "/httpServe/loginUser/resetPwd",
          {
            id: this.id_str,
            // pass: this.form.newPassword1,
            // newPass: this.form.newPassword2
            pass: utils.common.encrypt(
              this.form.newPassword1,
              "SM4",
              $.getSecretKey(this.userName)
            ),
            newPass: utils.common.encrypt(
              this.form.newPassword2,
              "SM4",
              $.getSecretKey(this.userName)
            ),
          },
          true
        )
        .then(() => {
          this.$message({
            message: this.$t("UserManage.adminPage.passModifySuccess"),
            type: "success",
            offset: 100,
          });
          this.form = {};
          this.$refs.form.clearValidate();
          this.statusVisiblemm = false;
        });
    },

    //新增按钮
    addNew() {
      //切换到新增页面
      this.flag = 2;
    },
    //新增保存
    addSaveData(data) {
      //切换到表格页面
      this.flag = data;
      this.getTableList();

      this.$message({
        message: this.$t("public.SuccessfullySaved"),
        type: "success",
        offset: 100,
      });
    },
    //编辑按钮
    edit(row) {
      console.log(row, "bianji shuju ");
      this.editRow = row;
      this.flag = 3;
    },
    //编辑保存
    editSaveData(data) {
      //切换到表格页面
      this.flag = data;
      this.getTableList();

      this.$message({
        message: this.$t("public.SuccessfullySaved"),
        type: "success",
        offset: 100,
      });
    },
    //解锁
    unlockIncident(val) {
      var idStr = val.id.toString();
      var parmas = {
        status: "1", // 1-正常 2-锁定 ids: val.id
        ids: [idStr],
      };
      this.$confirm(
        this.$t("UserManage.adminPage.sureUnlocking"),
        this.$t("UserManage.adminPage.ReminderInfo"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(async () => {
          const res = await this.$axios.post(
            "/httpServe/userInfo/updateStatus",
            parmas,
            true
          );
          console.log(parmas, "parmas2");
          if (res.data == -1) {
            this.$message({
              message: this.$t("UserManage.adminPage.UnlockSelecFailed"),
              type: "error",
              offset: 100,
            });
            this.getTableList();
            this.$refs.multipleTable.clearSelection();
          }
          if (res.data == 0) {
            this.$message({
              message: this.$t("UserManage.adminPage.SuccessUnlockeSelecAdmin"),
              type: "success",
              offset: 100,
            });
            this.getTableList();
            this.$refs.multipleTable.clearSelection();
          }
        })
        .catch(() => {
          return false;
        });
    },
    //解锁
    unlockBtn(obj) {
      var idArr = [];
      obj.forEach((item) => {
        idArr.push(item.id);
      });
      console.log(idArr.id, "idArr------------------");
      var parmas = {
        status: "1", // 1-正常 2-锁定 ids: val.id
        ids: idArr,
      };
      console.log(parmas, "parmas按钮---------------");
      this.$confirm(
        this.$t("UserManage.adminPage.sureUnlocking"),
        this.$t("UserManage.adminPage.ReminderInfo"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(async () => {
          const res = await this.$axios.post(
            "/httpServe/userInfo/updateStatus",
            parmas,
            true
          );
          console.log(parmas, "parmas1");
          if (res.data == -1) {
            this.$message({
              message: this.$t("UserManage.adminPage.UnlockSelecFailed"),
              type: "error",
              offset: 100,
            });
            this.getTableList();
            this.$refs.multipleTable.clearSelection();
          }
          if (res.data == 0) {
            this.$message({
              message: this.$t("UserManage.adminPage.SuccessUnlockeSelecAdmin"),
              type: "success",
              offset: 100,
            });

            this.getTableList();
            this.$refs.multipleTable.clearSelection();
          }
        })
        .catch(() => {
          return false;
        });
    },
    handleSelectionChange(obj) {
      this.multipleSelection = obj;
      this.delArr = obj;
    },
    //全选
    selectAll(selection) {
      this.check(selection);
    },
    //单个删除
    details(row) {
      console.log(row.deviceCount, "管理员绑定设备数");
      var params = { ids: [row.id] };
      if (row.deviceCount >= 1) {
        this.$message({
          message: this.$t("UserManage.adminPage.boundDevPresent"),
          type: "error",
          offset: 100,
        });
        this.$refs.multipleTable.clearSelection();
        return false;
      } else {
        this.$confirm(
          this.$t("UserManage.adminPage.sureDelete"),
          this.$t("UserManage.adminPage.ReminderInfo"),
          {
            confirmButtonText: this.$t("public.Verify"),
            cancelButtonText: this.$t("public.Cancel"),
            type: "warning",
            customClass: "persdsd",
          }
        )
          .then(async () => {
            this.selectVal = {};
            const res = await this.$axios
              .post("/httpServe/manage/delete", params, true)
                if (res.data == 0) {
                  this.$message({
                    message: this.$t("public.SuccessfullyDeleted"),
                    type: "success",
                    offset: 100,
                  });
                  this.page.currentPage = 1;
                  this.getTableList(); //删除成功后调用表格数据
                  this.$refs.multipleTable.clearSelection();
                }else  {
                  this.$message({
                    message: this.$t("UserManage.adminPage.delMessage"),
                    type: "error",
                    offset: 100,
                  });
                  this.$refs.multipleTable.clearSelection();
                }
          })
          .catch(() => {
            return false;
          });
      }
    },
    //多选删除
    delMuch(obj) {
      var ids = [];
      obj.forEach((item) => {
        console.log(item.deviceCount, "管理员绑定设备数");
        ids.push(item.id);
        if (item.deviceCount >= 1) {
          this.devCount = true;
          return false;
        } else {
          this.devCount = false;
        }
      });

      var params = { ids: ids };
      console.log(this.devCount, "devCount");
      if (this.devCount) {
        this.$message({
          message: this.$t("UserManage.adminPage.boundDevPresent"),
          type: "error",
          offset: 100,
        });
        this.$refs.multipleTable.clearSelection();
        return false;
      } else {
        this.$confirm(
          this.$t("UserManage.adminPage.sureDelete"),
          this.$t("UserManage.adminPage.ReminderInfo"),
          {
            confirmButtonText: this.$t("public.Verify"),
            cancelButtonText: this.$t("public.Cancel"),
            type: "warning",
            customClass: "persdsd",
          }
        )
          .then(async () => {
            this.selectVal = {};
            const res = await this.$axios
              .post("/httpServe/manage/delete", params, true)
              if (res.data == 0) {
                  this.$message({
                    message: this.$t("public.SuccessfullyDeleted"),
                    type: "success",
                    offset: 100,
                  });
                  this.page.currentPage = 1;
                  this.getTableList(); //删除成功后调用表格数据
                  this.$refs.multipleTable.clearSelection();
                }else  {
                  this.$message({
                    message: this.$t("UserManage.adminPage.delMessage"),
                    type: "error",
                    offset: 100,
                  });
                  this.$refs.multipleTable.clearSelection();
                }
          })
          .catch(() => {
            return false;
          });
      }
    },
    // 复选框勾选
    checkChange(selection, row) {
      console.log(selection, "selection--------");
      this.check(selection);
    },
    // 1、单选是正常的用户--停用高亮--启用跟解锁置灰；停用的用户--启用高亮，停用跟解锁置灰；锁定的用户--解锁高亮，停用跟启用置灰
    // 2、批量选择全部高亮，、，能执行的执行，不能执行的不执行（启用-只针对停用；锁定和正常都可以停用）
    check(selection) {
      let arr1 = [];
      let arr2 = [];
      // selection.forEach((item) => {
      //   // item.statusStr == '停用'      // this.button_startType = 'primary'

      //   arr1.push(item.id)
      //   //去重
      //   this.delArr = [...new Set(arr1)]
      // })
      console.log(selection, "selection");
      if (selection.length == 1) {
        arr2 = selection;
        arr2.forEach((items) => {
          if (items.statusStr == "正常") {
            this.button_comType = "primary";
            this.comFlag = false;
            this.button_stopType = "primary";
            this.stopFlag = false;
            this.button_delType = "primary";
            this.delFlag = false;
            //解锁按钮不可点击 button_unlock
            this.button_unlockType = "info";
            this.unlockFlag = true;
            //启用按钮不可点击置灰
            this.button_startType = "info";
            this.startFlag = true;
          }
          if (items.statusStr == "锁定") {
            this.button_comType = "primary"; //普通按钮
            this.comFlag = false;
            this.button_stopType = "info"; //停用按钮
            this.stopFlag = true;
            this.button_delType = "primary";
            this.delFlag = false;
            //解锁按钮可点击 button_unlock
            this.button_unlockType = "primary";
            this.unlockFlag = false;
            //启用按钮不可点击置灰
            this.button_startType = "info";
            this.startFlag = true;
          }
          if (items.statusStr == "停用") {
            //停用按钮不可点击置灰
            this.button_stopType = "info";
            this.stopFlag = true;
            //启用按钮不可点击置灰
            this.button_startType = "primary";
            this.startFlag = false;
            //普通按钮可点击
            this.button_comType = "primary";
            this.comFlag = false;
            //删除按钮可点击
            this.button_delType = "primary";
            this.delFlag = false;
            //解锁按钮可点击 button_unlock
            this.button_unlockType = "info";
            this.unlockFlag = true;
          }
        });
      } else if (selection.length >= 2) {
        this.button_comType = "primary";
        this.comFlag = false;
        this.button_stopType = "primary";
        this.stopFlag = false;
        this.button_startType = "primary";
        this.startFlag = false;
        this.button_delType = "primary";
        this.delFlag = false;
        //解锁按钮不可点击 button_unlock
        this.button_unlockType = "primary";
        this.unlockFlag = false;
      } else {
        this.button_comType = "info"; //普通按钮
        this.comFlag = true;
        this.button_stopType = "info"; //停用按钮
        this.stopFlag = true;
        this.button_startType = "info"; //启用按钮
        this.startFlag = true;
        this.button_delType = "info"; //删除按钮
        this.delFlag = true;
        //解锁按钮不可点击 button_unlock
        this.button_unlockType = "info";
        this.unlockFlag = true;
      }

      // else if (selection.length == 1) {
      //   arr2 = selection
      //   //正常状态不可启用  停用状态可启用不可停用
      //   arr2.forEach((items) => {
      //     if (items.statusStr == '正常') {
      //       //普通按钮可点击
      //       this.button_comType = 'primary'
      //       this.comFlag = false
      //       //停用按钮
      //       this.button_stopType = 'primary'
      //       this.stopFlag = false
      //       //删除按钮
      //       this.button_delType = 'primary'
      //       this.delFlag = false
      //       //启用按钮不可点击置灰
      //       this.button_startType = 'info'
      //       this.startFlag = true
      //       //解锁按钮不可点击 button_unlock
      //       this.button_unlockType = 'primary'
      //       this.unlockFlag = false
      //     }
      //     if (items.statusStr == '锁定') {
      //       this.button_comType = 'primary'
      //       this.comFlag = false
      //       this.button_stopType = 'primary'
      //       this.stopFlag = false
      //       this.button_delType = 'primary'
      //       this.delFlag = false

      //       //解锁按钮可点击 button_unlock
      //       this.button_unlockType = 'primary'
      //       this.unlockFlag = false
      //     }
      //     if (items.statusStr == '停用') {
      //       i++
      //       //停用按钮不可点击置灰
      //       this.button_stopType = 'info'
      //       this.stopFlag = true
      //       //启用按钮不可点击置灰
      //       this.button_startType = 'primary'
      //       this.startFlag = false
      //       //普通按钮可点击
      //       this.button_comType = 'primary'
      //       this.comFlag = false
      //       //删除按钮可点击
      //       this.button_delType = 'primary'
      //       this.delFlag = false
      //       //解锁按钮可点击 button_unlock
      //       this.button_unlockType = 'info'
      //       this.unlockFlag = true
      //     }
      //   })
      // } else {
      //   this.button_comType = 'info'
      //   this.comFlag = true
      //   this.button_stopType = 'info'
      //   this.stopFlag = true
      //   this.button_startType = 'info'
      //   this.startFlag = true
      //   this.button_delType = 'info'
      //   this.delFlag = true
      //   //解锁按钮不可点击 button_unlock
      //   this.button_unlockType = 'info'
      //   this.unlockFlag = true
      // }
      // console.log(i,"-----")
      // if(i!=0){
      //   //解锁按钮可点击 button_unlock
      //   this.button_unlockType = 'info'
      //   this.unlockFlag = true
      // }
    },
    //关联设备
    deviceInfo(row) {
      this.rowId = row.id;
      this.statusVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.formBox {
  width: 100%;
  height: 100%;
  padding: 10px 10px 0px 10px !important; //页面最外层上右左10
  min-width: 1500px;
  font-family: Microsoft YaHei;
}
.elRow {
  margin-bottom: 10px;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>
