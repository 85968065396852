<template>
  <div class="addAdmin" style="margin-left: 60px; margin-top: 25px">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm addAdminForm"
      @submit.native.prevent
    >
      <el-form-item :label="$t('public.Username') + '：'" prop="loginName">
        <el-input
          v-model.trim="ruleForm.loginName"
          size="small"
          style="width: 250px"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('public.Name') + '：'" prop="userName">
        <el-input
          v-model.trim="ruleForm.userName"
          size="small"
          style="width: 250px"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('public.Phone') + '：'" prop="phone">
        <el-input
          v-model.trim="ruleForm.phone"
          maxlength="12"
          size="small"
          style="width: 250px"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('public.Email') + '：'" prop="email">
        <el-input
          v-model.trim="ruleForm.email"
          size="small"
          style="width: 250px"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('UserManage.adminPage.gender') + '：'"
        prop="sex"
      >
        <el-select
          v-model.trim="ruleForm.userSex"
          size="small"
          clear="select"
          style="width: 250px"
        >
          <el-option
            v-for="item in sexOptions"
            :key="item.value"
            :label="$t(`UserManage.adminPage.${item.label}`)"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        :label="$t('UserManage.adminPage.SendingType') + '：'"
        prop="sendType"
      >
        <el-radio v-model.trim="ruleForm.sendType" label="2">{{
          $t("UserManage.adminPage.mail")
        }}</el-radio>
        <!-- <el-radio v-model="ruleForm.sendType" label="1">短信</el-radio> -->
      </el-form-item>
      <el-form-item
        :label="$t('UserManage.adminPage.role') + '：'"
        prop="userRole"
        :rules="[
          {
            required: true,
            message: $t('UserManage.adminPage.userRoleVoid'),
            trigger: 'change',
          },
        ]"
      >
        <el-select
          v-model.trim="ruleForm.userRole"
          size="small"
          clear="select"
          style="width: 250px"
          @change="selectChange"
        >
          <el-option
            v-for="item in roleOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label="$t('public.Section') + '：'"
        required
        class="groups"
      >
        <el-input
          type="textarea"
          disabled
          :title="textarea1"
          v-model="textarea1"
          style="width: 250px"
        >
        </el-input>
        <el-button
          size="small"
          type="primary"
          @click="changeGroup"
          style="margin-left: 10px"
          >{{ $t("UserManage.adminPage.SelectDepartment") }}</el-button
        >
        <!--  -->
      </el-form-item>
      <el-form-item
        :label="$t('UserManage.adminPage.ManageDepartment') + '：'"
        required
        v-show="treeManagement"
        class="mGroup"
      >
        <el-input
          type="textarea"
          disabled
          :title="textarea2"
          v-model="textarea2"
          style="width: 250px"
        >
        </el-input>
        <el-button
          size="small"
          type="primary"
          @click="changeManGroup"
          style="margin-left: 10px"
          >{{ $t("UserManage.adminPage.SelectManageDepartment") }}</el-button
        >
        <!--  -->
      </el-form-item>
      <el-form-item
        :label="$t('UserManage.adminPage.password') + '：'"
        v-show="echo"
      >
        <el-radio-group v-model="radio">
          <!-- this.$utils.common.encrypt(this.form.pass,"SM4",secretKey) -->
          <el-radio :label="1" @change="pasWordChange(1)">{{
            $t("UserManage.adminPage.DefaultPass")
          }}</el-radio>
          <el-radio :label="2" @change="pasWordChange(2)">{{
            $t("UserManage.adminPage.custom")
          }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <div class="miMa" v-if="flag">
        <!-- <el-form style="height: 90px; margin-top: 10px" size="small" :model="form" :rules="rules1" ref="form" label-width="100px" class="demo-ruleForm restForm"> -->
        <el-form-item
          :label="$t('UserManage.adminPage.NewPass') + '：'"
          prop="newPassword1"
        >
          <el-input
            size="small"
            style="width: 250px"
            type="password"
            v-model="ruleForm.newPassword1"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          style="margin-top: 5px"
          :label="$t('UserManage.adminPage.ConfirmPass') + '：'"
          prop="newPassword2"
        >
          <el-input
            size="small"
            style="width: 250px"
            v-model="ruleForm.newPassword2"
            clearable
            type="password"
          ></el-input>
        </el-form-item>
        <!-- </el-form> -->
      </div>
      <el-form-item
        :label="$t('UserManage.adminPage.pour') + '：'"
        v-if="xianShi"
      >
        <span style="color: #bc0404"
          >{{ $t("UserManage.adminPage.DefaultPassIs")
          }}{{ defaultPassword }}</span
        >
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="small"
          @click="submitForm('ruleForm')"
          >{{ $t("public.Save") }}</el-button
        >
      </el-form-item>
    </el-form>
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose1"
      @determine="determine"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
    <!-- 选择部门弹框 -->
    <elDialog1
      class="dialog"
      :title="$t('UserManage.adminPage.SelectDepartment')"
      :btnTitle="$t('public.Verify')"
      :flagbtn="true"
      :cancelBtn="true"
      v-if="groupFlag"
      @determine="determine_g"
      :dialogVisible="groupFlag"
      :num="'470px'"
      @handleClose="handleClose"
      :determineBtn="true"
    >
      <radioTree @radioTree="radioTree1" />
    </elDialog1>
    <!-- 选择管理部门弹框 -->
    <elDialog1
      class="dialog"
      :title="$t('UserManage.adminPage.SelectManageDepartment')"
      :btnTitle="$t('public.Verify')"
      :flagbtn="true"
      :cancelBtn="true"
      v-if="groupManFlag"
      @determine="determine_m"
      :dialogVisible="groupManFlag"
      :num="'470px'"
      @handleClose="handleClose"
      :determineBtn="true"
    >
      <radioTree2 @radioTree="radioTree2" />
    </elDialog1>
  </div>
</template>

<script>
import elDialog1 from "@/components/elDialog/index.vue"; //弹框
import radioTree from "@/components/treeSulation/mangerTree";
import radioTree2 from "@/components/treeSulation/mangerssu";
import dialogInfo from "@/components/promptMessage/index";
import utils from "../../../utils/index.js";
export default {
  components: { dialogInfo, radioTree, radioTree2, elDialog1 },
  data() {
    //电话正则验证---暂时不需要 (!/^1[3456789]\d{9}$/.test(value))
    // const validatePhone = (rule, value, callback) => {
    //   undefined
    //   if (value === '') {
    //     undefined

    //     callback(new Error('请输入手机号'))
    //   } else {
    //     undefined

    //     if (!/^[0-9]*$/.test(value)) {
    //       undefined

    //       callback(new Error('请输入正确的手机号'))
    //     } else {
    //       undefined

    //       callback()
    //     }
    //   }
    // }
    return {
      xianShi: true,
      defaultPassword: "",
      echo: true,
      defaultPassword: "",
      radio: 1, //默认   默认密码
      flag: false, //默认不显示自定义密码框

      groupFullNameShowByIndex: "",
      radioName1: "",
      radioName2: "",
      groupManFlag: false, //弹框显示默认不显示---管理部门
      groupFlag: false, //弹框显示默认不显示---部门
      textarea1: "", //部门内容
      textarea2: "", //管理部门内容
      treeParentId: 0, // 懒加载初始化树列表
      treeManagement: true, //管理部门tree是否显示----系统管理员为隐藏
      currentNodeKey: 1,
      visibaelFlag: false, //提示消息默认--关闭
      titleInfo: "", // 提示框的提示语
      ruleForm: {
        loginName: "",
        userName: "",
        phone: "",
        email: "",
        userSex: "0", //性别下拉框绑定value
        sendType: "2", //发送方式
        userRoleStr: "", //角色下拉框显示字段
        userRole: "", //角色下拉框绑定至
        radio1: "", //部门树状图单选
        radio2: "", //管理部门树状图单选
        //重置密码
        // oldPassword: "",
        newPassword1: "",
        newPassword2: "",
      },
      //性别数据组
      sexOptions: [
        { value: "0", label: "男" },
        { value: "1", label: "女" },
      ],
      //角色数组
      roleOptions: [],
      //部门树状图数组
      groupData: [],
      //管理部门树状图数组
      groupDataManagement: [],
      defaultProps1: {
        children: "children",
        label: "name",
      },
      defaultProps2: {
        children: "children",
        label: "name",
      },
      checked1: false, //部门树状图是否有节点选中
      checked2: false, //管理部门树状图是否有节点选中
      rules: {
        loginName: [
          {
            required: true,
            message: this.$t("UserManage.adminPage.loginNameVoid"),
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (/^(\w){4,32}$/.test(value) == false) {
                callback(
                  new Error(this.$t("UserManage.adminPage.loginNameReg"))
                );
              } else {
                callback();
              }
            },
            trigger: "blur", //这里还有blur触发方法； 如果设为none，那么只有this.$refs[formName]['validate']时才会验证
          },
          {
            validator: (rule, value, callback) => {
              if (/^[\u4e00-\u9fa5\w]+$/.test(value) == false) {
                callback(
                  new Error(this.$t("UserManage.adminPage.NotSupported"))
                );
              } else {
                callback();
              }
            },
            trigger: "blur", //这里还有blur触发方法； 如果设为none，那么只有this.$refs[formName]['validate']时才会验证
          },
        ],
        userName: [
          {
            required: true,
            message: this.$t("UserManage.adminPage.userNameVoid"),
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (/^[\u4e00-\u9fa5\w]+$/.test(value) == false) {
                callback(
                  new Error(this.$t("UserManage.adminPage.NotSupported"))
                );
              } else {
                callback();
              }
            },
            trigger: "blur", //这里还有blur触发方法； 如果设为none，那么只有this.$refs[formName]['validate']时才会验证
          },
        ],
        phone: [
          {
            required: true,
            message: this.$t("UserManage.adminPage.phoneVoid"),
          },

          {
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error(this.$t("UserManage.adminPage.phoneVoid")));
              } else {
                if (!/^[0-9]*$/.test(value)) {
                  callback(new Error(this.$t("UserManage.adminPage.phoneReg")));
                } else {
                  callback();
                }
              }
            },
            trigger: "blur",
          },
          {
            min: 1,
            max: 12,
            message: this.$t("UserManage.adminPage.phoneStr"),
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: this.$t("UserManage.adminPage.emailVoid"),
            trigger: "blur",
          },
          {
            type: "email",
            message: "请输入正确的邮箱",
            trigger: ["blur", "change"],
          },
        ],
        // 密码
        newPassword1: [
          {
            required: true,
            message: this.$t("UserManage.adminPage.passwordVoid"),
            trigger: "blur",
          },
          {
            min: 8,
            max: 32,
            message: this.$t("UserManage.adminPage.passwordReg"),
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (
                /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)/.test(
                  value
                ) == false
              ) {
                callback(
                  new Error(this.$t("UserManage.adminPage.passwordReg"))
                );
              } else {
                callback();
              }
            },
            trigger: "blur",
            // trigger: 'change' //这里还有blur触发方法； 如果设为none，那么只有this.$refs[formName]['validate']时才会验证
          },
        ],
        newPassword2: [
          {
            required: true,
            message: this.$t("UserManage.adminPage.ConfirmPassVoid"),
            trigger: "blur",
          },
          {
            min: 8,
            max: 32,
            message: this.$t("UserManage.adminPage.passwordReg"),
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(
                  new Error(this.$t("UserManage.adminPage.ConfirmPassVoid"))
                );
              } else if (
                /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)/.test(
                  value
                ) == false
              ) {
                callback(
                  new Error(this.$t("UserManage.adminPage.passwordReg"))
                );
              } else if (value !== this.ruleForm.newPassword1) {
                callback(
                  new Error(this.$t("UserManage.adminPage.PassRepetition"))
                );
              }
              // else if (value === this.form.oldPassword) {
              //   callback(new Error('新密码不能与原密码相同!'))
              // }
              else {
                callback();
              }
            },
            trigger: "blur", //这里还有blur触发方法； 如果设为none，那么只有this.$refs[formName]['validate']时才会验证
          },
        ],
        sendType: [],
      },
    };
  },
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
    //角色下拉框数据
    this.getRoleData();
    this.searchPasWord();
  },
  methods: {
    //限制输入特殊字符
    btKeyUp(e) {
      e.target.value = e.target.value
        .replace(
          /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
          ""
        )
        .replace(/\s/g, "");
    },
    handleClose() {
      this.visibaelFlag = false; //提示消息  关闭
      this.groupFlag = false; //部门树弹框关闭
      this.groupManFlag = false; //管理部门树弹框关闭
      this.ruleForm.radio2 = ""; //单选框选中id
      this.radioName2 = ""; //单选框选中管理部门名称---暂时存放
      this.ruleForm.radio2 = ""; //单选框选中id
      this.radioName2 = ""; //单选框选中管理部门名称---暂时存放
    },
    handleClose1() {
      this.visibaelFlag = false; //提示消息  关闭
    },
    //确定回调事件
    determine() {
      // debugger
      this.visibaelFlag = false; //提示消息  关闭
    },
    //获取角色下拉框数据
    async getRoleData() {
      var params = {
        currentPage: 1,
        pageSize: 999,
        orderColume: "lastUpdateTime",
        orderRule: "DESC",
        name: "",
      };

      const res = await this.$axios.post(
        "/httpServe/role/getDataInfo",
        params,
        true
      );
      console.log(res.data.content, "获取角色下拉框数据");
      this.roleOptions = res.data.content;
      console.log(this.findIdByName(res.data.content, "安全管理员"), "");
      if (this.findIdByName(res.data.content, "安全管理员") == null) {
        this.ruleForm.userRole = res.data.content[0].id;
        this.ruleForm.userRoleStr = res.data.content[0].name;
      } else {
        this.ruleForm.userRole = this.findIdByName(
          res.data.content,
          "安全管理员"
        ).id;
        this.ruleForm.userRoleStr = this.findIdByName(
          res.data.content,
          "安全管理员"
        ).name;
      }
    },
    findIdByName(array, name) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].name === name) {
          return array[i];
        }
      }
      return null; // 如果没有找到，返回 null
    },
    //角色下拉框change事件
    selectChange(val) {
      console.log(val, "角色下拉框change事件");
      this.ruleForm.userRole = val;
      this.roleOptions.forEach((item) => {
        if (item.id == val) {
          this.ruleForm.userRoleStr = item.name;
        }
      });
      if (val == 3) {
        this.treeManagement = false;
      } else {
        this.treeManagement = true;
      }
    },
    radioTree1(a, b) {
      if (a == sessionStorage.getItem("manageGroupId")) {
        this.radioName1 = "";
      } else {
        this.ruleForm.radio1 = a; //单选框选中id
        var str1 = b.groupFullName + "•" + b.name;
        this.radioName1 = str1.substr(this.groupFullNameShowByIndex); //单选框选中部门名称---暂时存放
      }
    },
    radioTree2(a, b) {
      if (a == sessionStorage.getItem("manageGroupId")) {
        this.radioName2 = "";
      } else {
        this.ruleForm.radio2 = a; //单选框选中id
        var str2 = b.groupFullName + "•" + b.name;
        this.radioName2 = str2.substr(this.groupFullNameShowByIndex); //单选框选中管理部门名称---暂时存放
        console.log(this.radioName2, "this.radioName2 ");
      }
    },
    //选择部门
    changeGroup() {
      this.groupFlag = true;
    },
    //选择管理部门
    changeManGroup() {
      this.groupManFlag = true;
    },
    //部门确定按钮
    determine_g() {
      console.log(this.radioName1, "this.radioName1");
      if (!this.radioName1) {
        this.groupFlag = true;
        this.$message({
          message: this.$t("UserManage.adminPage.PleaseSelectDepartment"),
          type: "error",
          offset: 100,
        });
      } else {
        this.textarea1 = this.radioName1; //将选中的部门名称渲染到对应页面文本框中
        this.groupFlag = false;
      }
    },
    //管理部门确定按钮
    determine_m() {
      if (!this.radioName2) {
        this.groupManFlag = true;
        this.$message({
          message: this.$t("UserManage.adminPage.PleaseSelectManageDepartment"),
          type: "error",
          offset: 100,
        });
      } else {
        this.textarea2 = this.radioName2; //将选中的管理部门名称渲染到对应页面文本框中
        this.groupManFlag = false;
      }
    },

    //保存
    submitForm(formName) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          //验证通过保存数据
          if (this.ruleForm.radio1 == "") {
            this.$message({
              message: this.$t("UserManage.adminPage.PleaseSelectDepartment"),
              type: "error",
              offset: 100,
            });
          } else if (this.treeManagement===true&&this.ruleForm.radio2 == "") {
            this.$message({
              message: this.$t(
                "UserManage.adminPage.PleaseSelectManageDepartment"
              ),
              type: "error",
              offset: 100,
            });
          } else {
            this.savaAdd();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    pasWordChange(val) {
      console.log(val, "------");
      if (val == 1) {
        this.flag = false;
        this.xianShi = true;
      } else if (val == 2) {
        this.flag = true;
        this.xianShi = false;
      }
    },
    // 查询接口
    async searchPasWord() {
      var param = {};
      // console.log(data);
      const res = await this.$axios.post(
        "httpServe/security/config/selectDefaultPass",
        param,
        true
      );
      console.log(res, "查询默认密码");
      // utils.common.encrypt(this.ruleForm.pass,"SM4",secretKey)
      this.defaultPassword = utils.common.decrypt(res.data, "");
    },
    async savaAdd() {
      if (this.radio == 1) {
        this.ruleForm.newPassword2 = this.defaultPassword;
      }
      var aa = {
        loginName: this.ruleForm.loginName,
        userName: this.ruleForm.userName,
        phone: this.ruleForm.phone,
        email: this.ruleForm.email,
        userSex: this.ruleForm.userSex,
        sendType: this.ruleForm.sendType,
        userRole: this.ruleForm.userRole,
        groupId: this.ruleForm.radio1,
        action: "add",
        manageGroupId: this.ruleForm.radio2,
        pass: utils.common.encrypt(
          this.ruleForm.newPassword2,
          "SM4",
          $.getSecretKey(this.ruleForm.loginName)
        ),
      };
      console.log(aa, "新增管理员保存参数");

      const res = await this.$axios.post("/httpServe/manage/insert", aa, true);
      console.log(res, "新增管理员保存");
      if (res.data == -1) {
        this.$message({
          message: this.$t("UserManage.adminPage.UserAlreadyExists"),
          type: "error",
          offset: 100,
        });
      } else if (res.data == -2) {
        this.$message({
          message: this.$t("UserManage.adminPage.AddAdminFailed"),
          type: "error",
          offset: 100,
        });
      } else if (res.data == 0) {
        this.$emit("addSave", "1");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.addAdminForm {
  .el-form-item {
    margin-bottom: 17px !important;
  }
}
.box {
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  padding: 5px 0px;
  width: 250px;
  height: 180px;
  overflow: auto;
}
.el-tree-node:hover {
  background-color: #ffe6b0;
}
.box-corn {
  ::v-deep .el-tree-node__content {
    display: block;
    width: 600px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
<style>
.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #ffe6b0;
}
</style>
