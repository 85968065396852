<template>
  <div class="addAdmin" style="margin-left: 60px; margin-top: 25px">
    <!-- {{editRows}} -->
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm addAdminForm"
    >
      <el-form-item :label="$t('public.Username') + '：'" prop="loginName">
        <el-input
          v-model="ruleForm.loginName"
          size="small"
          style="width: 250px"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('public.Name') + '：'" prop="userName">
        <el-input
          v-model="ruleForm.userName"
          size="small"
          style="width: 250px"
          :disabled="userDisabled"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('public.Phone') + '：'" prop="phone">
        <el-input
          v-model="ruleForm.phone"
          maxlength="12"
          size="small"
          style="width: 250px"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('public.Email') + '：'" prop="email">
        <el-input
          v-model="ruleForm.email"
          size="small"
          style="width: 250px"
        ></el-input>
      </el-form-item>
      <!-- {{ruleForm.userSex}} -->
      <el-form-item
        :label="$t('UserManage.adminPage.gender') + '：'"
        prop="sex"
      >
        <el-select
          v-model="ruleForm.userSex"
          size="small"
          clear="select"
          style="width: 250px"
        >
          <el-option
            v-for="item in sexOptions"
            :key="item.value"
            :label="$t(`UserManage.adminPage.${item.label}`)"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- {{ruleForm.sendType}}
      <el-form-item label="发送方式：" required>
        <el-radio v-model="ruleForm.sendType" label="2">邮件</el-radio>
        <el-radio v-model="ruleForm.sendType" label="1">短信</el-radio>
      </el-form-item> -->
      <el-form-item
        :label="$t('UserManage.adminPage.role') + '：'"
        prop="userRole"
        :rules="[
          { required: true, message: $t('UserManage.adminPage.userRoleVoid'), trigger: 'change' },
        ]"
      >
        <el-select
          v-model="ruleForm.userRole"
          size="small"
          clear="select"
          style="width: 250px"
          @change="selectChange"
        >
          <el-option
            v-for="item in roleOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label="$t('public.Section') + '：'"
        required
        class="groups"
      >
        <el-input
          type="textarea"
          disabled
          :title="textarea1"
          v-model="textarea1"
          style="width: 250px"
        >
        </el-input>
        <el-button
          size="small"
          type="primary"
          @click="changeGroup"
          style="margin-left: 10px"
          >{{ $t("UserManage.adminPage.SelectDepartment") }}</el-button
        >
      </el-form-item>

      <el-form-item
        :label="$t('UserManage.adminPage.ManageDepartment') + '：'"
        required
        v-show="treeManagement"
        class="mGroup"
      >
        <el-input
          type="textarea"
          disabled
          :title="textarea2"
          v-model="textarea2"
          style="width: 250px"
        >
        </el-input>
        <el-button
          size="small"
          type="primary"
          @click="changeManGroup"
          style="margin-left: 10px"
          >{{ $t("UserManage.adminPage.SelectManageDepartment") }}</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="small"
          @click="submitForm('ruleForm')"
          >{{ $t("public.Save") }}</el-button
        >
      </el-form-item>
    </el-form>
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose1"
      @determine="determine"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
    <!-- 选择部门弹框 -->
    <elDialog
      class="dialog"
      :title="$t('UserManage.adminPage.SelectDepartment')"
      :btnTitle="$t('public.Verify')"
      v-if="groupFlag"
      :flagbtn="true"
      :cancelBtn="true"
      @determine="determine_g"
      :dialogVisible="groupFlag"
      :num="'470px'"
      @handleClose="handleClose"
      :determineBtn="true"
    >
      <radioTree @radioTree="radioTree1" />
    </elDialog>
    <!-- 选择管理部门弹框 -->
    <elDialog
      class="dialog"
      :title="$t('UserManage.adminPage.SelectManageDepartment')"
      :btnTitle="$t('public.Verify')"
      v-if="groupManFlag"
      :flagbtn="true"
      :cancelBtn="true"
      @determine="determine_m"
      :dialogVisible="groupManFlag"
      :num="'470px'"
      @handleClose="handleClose"
      :determineBtn="true"
    >
      <radioTree2 @radioTree="radioTree2" />
    </elDialog>
  </div>
</template>

<script>
import radioTree from "@/components/treeSulation/mangerTree";
import radioTree2 from "@/components/treeSulation/mangerssu";
import elDialog from "@/components/elDialog/index.vue"; //弹框
import dialogInfo from "@/components/promptMessage/index";
export default {
  components: { dialogInfo, elDialog, radioTree, radioTree2 },
  props: {
    editRows: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    //电话正则验证---暂时不需要 (!/^1[3456789]\d{9}$/.test(value))
    // const validatePhone = (rule, value, callback) => {
    //   undefined
    //   if (value === '') {
    //     undefined

    //     callback(new Error('请输入手机号'))
    //   } else {
    //     undefined

    //     if (!/^[0-9]*$/.test(value)) {
    //       undefined

    //       callback(new Error('请输入正确的手机号'))
    //     } else {
    //       undefined

    //       callback()
    //     }
    //   }
    // }
    return {
      groupFullNameShowByIndex: "",
      radioName1: "",
      radioName2: "",
      groupManFlag: false, //弹框显示默认不显示---管理部门
      groupFlag: false, //弹框显示默认不显示---部门
      textarea1: "", //部门内容
      textarea2: "", //管理部门内容
      treeManagement: true, //管理部门tree是否显示----系统管理员为隐藏
      userDisabled: false, //编辑姓名是否禁用---默认禁用
      currentNodeKey1: 1,
      currentNodeKey2: 1,
      visibaelFlag: false, //提示消息默认--关闭
      titleInfo: "", // 提示框的提示语
      ruleForm: {
        id: 0,
        loginName: "",
        userName: "",
        userSex: "0",
        phone: "",
        email: "",
        userRole: "",
        manageRange: 0,
        userStatus: 0,
        useDeviceNum: 0,
        groupIds: "",
        groupId: 0,
        sendType: 0,
        status: 1,
        manageGroupId: 0,
        deviceCount: 0,
      },
      //性别数据组
      sexOptions: [
        { value: "0", label: "男" },
        { value: "1", label: "女" },
      ],
      //角色数组
      roleOptions: [],
      //部门树状图数组
      groupData: [],
      //管理部门树状图数组
      groupDataManagement: [],
      defaultProps1: {
        children: "children",
        label: "name",
      },
      defaultProps2: {
        children: "children",
        label: "name",
      },
      checked1: false, //部门树状图是否有节点选中
      checked2: false, //管理部门树状图是否有节点选中
      rules: {
        loginName: [
          {
            required: true,
            message: this.$t("UserManage.adminPage.loginNameVoid"),
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (/^(\w){4,32}$/.test(value) == false) {
                callback(
                  new Error(this.$t("UserManage.adminPage.loginNameReg"))
                );
              } else {
                callback();
              }
            },
            trigger: "blur", //这里还有blur触发方法； 如果设为none，那么只有this.$refs[formName]['validate']时才会验证
          },
          {
            validator: (rule, value, callback) => {
              if (/^[\u4e00-\u9fa5\w]+$/.test(value) == false) {
                callback(
                  new Error(this.$t("UserManage.adminPage.NotSupported"))
                );
              } else {
                callback();
              }
            },
            trigger: "blur", //这里还有blur触发方法； 如果设为none，那么只有this.$refs[formName]['validate']时才会验证
          },
        ],
        userName: [
          {
            required: true,
            message: this.$t("UserManage.adminPage.userNameVoid"),
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (/^[\u4e00-\u9fa5\w]+$/.test(value) == false) {
                callback(
                  new Error(this.$t("UserManage.adminPage.NotSupported"))
                );
              } else {
                callback();
              }
            },
            trigger: "blur", //这里还有blur触发方法； 如果设为none，那么只有this.$refs[formName]['validate']时才会验证
          },
        ],
        phone: [
          {
            required: true,
            message: this.$t("UserManage.adminPage.phoneVoid"),
          },

          {
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error(this.$t("UserManage.adminPage.phoneVoid")));
              } else {
                if (!/^[0-9]*$/.test(value)) {
                  callback(new Error(this.$t("UserManage.adminPage.phoneReg")));
                } else {
                  callback();
                }
              }
            },
            trigger: "blur",
          },
          {
            min: 1,
            max: 12,
            message: this.$t("UserManage.adminPage.phoneStr"),
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: this.$t("UserManage.adminPage.emailVoid"),
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (/^\w{3,15}\@\w+\.[a-z]{2,3}$/.test(value) == false) {
                callback(new Error(this.$t("UserManage.adminPage.emailReg")));
              } else {
                callback();
              }
            },
            trigger: "blur", //这里还有blur触发方法； 如果设为none，那么只有this.$refs[formName]['validate']时才会验证
          },
        ],
      },
      //编辑回显的整条数据
      curRow: {},
      //拿到所有数据
      treeAll: [],
    };
  },
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
    //角色下拉框数据
    this.getRoleData();
    //获取管理部门树状图数据
    // this.getTreeData()
    //数据回显
    this.rowData();
  },
  methods: {
    handleClose() {
      this.visibaelFlag = false; //提示消息  关闭
      this.groupFlag = false; //部门树弹框关闭
      this.groupManFlag = false; //管理部门树弹框关闭
    },
    handleClose1() {
      this.visibaelFlag = false; //提示消息  关闭
    },
    //确定回调事件
    determine() {
      this.visibaelFlag = false; //提示消息  关闭
    },

    // 回显数据
    rowData() {
      if (this.editRows.groupId == 1) {
        this.userDisabled = true;
      } else {
        this.userDisabled = false;
      }
      if (this.editRows.role.name == "系统管理员") {
        this.treeManagement = false;
      } else {
        this.treeManagement = true;
      }
      (this.ruleForm.id = this.editRows.id),
        (this.ruleForm.loginName = this.editRows.loginName),
        (this.ruleForm.userName = this.editRows.userName),
        (this.ruleForm.userSex = this.editRows.userSex.toString()),
        (this.ruleForm.phone = this.editRows.phone),
        (this.ruleForm.email = this.editRows.email),
        (this.ruleForm.userRole = this.editRows.userRole), //角色下拉框绑定至
        (this.ruleForm.userStatus = this.editRows.userStatus),
        (this.ruleForm.manageRange = this.editRows.manageRange),
        (this.ruleForm.useDeviceNum = this.editRows.useDeviceNum),
        (this.ruleForm.groupId = this.editRows.groupId),
        (this.ruleForm.sendType = this.editRows.sendType),
        (this.ruleForm.status = this.editRows.status),
        (this.ruleForm.manageGroupId = this.editRows.manageGroupId),
        (this.ruleForm.deviceCount = this.editRows.deviceCount),
        (this.textarea1 = this.editRows.groupFullName.substr(
          this.groupFullNameShowByIndex
        )),
        (this.textarea2 = this.editRows.manageGroupFullName.substr(
          this.groupFullNameShowByIndex
        ));
    },
    //获取角色下拉框数据
    async getRoleData() {
      var params = {
        currentPage: 1,
        pageSize: 999,
        orderColume: "lastUpdateTime",
        orderRule: "DESC",
        name: "",
      };
      const res = await this.$axios.post(
        "/httpServe/role/getDataInfo",
        params,
        true
      );
      console.log(res.data.content, "获取角色下拉框数据");
      this.roleOptions = res.data.content;
    },
    //角色下拉框change事件
    selectChange(val) {
      console.log(val, "角色下拉框change事件");
      this.ruleForm.userRole = val;
      if (val == 3) {
        this.treeManagement = false;
      } else {
        this.treeManagement = true;
      }
    },
    radioTree1(a, b) {
      if (a == sessionStorage.getItem("manageGroupId")) {
        this.radioName1 = "";
      } else {
        this.ruleForm.groupId = a; //单选框选中id
        var str1 = b.groupFullName + "•" + b.name;
        this.radioName1 = str1.substr(this.groupFullNameShowByIndex); //单选框选中部门名称---暂时存放
      }
    },
    radioTree2(a, b) {
      if (a == sessionStorage.getItem("manageGroupId")) {
        this.radioName2 = "";
      } else {
        this.ruleForm.manageGroupId = a; //单选框选中id
        var str2 = b.groupFullName + "•" + b.name;
        this.radioName2 = str2.substr(this.groupFullNameShowByIndex); //单选框选中管理部门名称---暂时存放
      }
    },
    //选择部门
    changeGroup() {
      this.groupFlag = true;
    },
    //选择管理部门
    changeManGroup() {
      this.groupManFlag = true;
    },
    //部门确定按钮
    determine_g() {
      if (!this.radioName1) {
        this.groupFlag = true;
        this.$message({
          message: this.$t("UserManage.adminPage.PleaseSelectDepartment"),
          type: "error",
          offset: 100,
        });
      } else {
        this.textarea1 = this.radioName1; //将选中的部门名称渲染到对应页面文本框中
        this.groupFlag = false;
      }
    },
    //管理部门确定按钮
    determine_m() {
      if (!this.radioName2) {
        this.groupManFlag = true;
        this.$message({
          message: this.$t("UserManage.adminPage.PleaseSelectManageDepartment"),
          type: "error",
          offset: 100,
        });
      } else {
        this.textarea2 = this.radioName2; //将选中的管理部门名称渲染到对应页面文本框中
        this.groupManFlag = false;
      }
    },
    //保存
    submitForm(formName) {
      console.log(this.ruleForm, "用户名");
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //验证通过保存数据
          if (this.ruleForm.groupId == "") {
            this.$message({
              message: this.$t("UserManage.adminPage.PleaseSelectDepartment"),
              type: "error",
              offset: 100,
            });
          } else if (this.treeManagement===true&&this.ruleForm.manageGroupId == "") {
            this.$message({
              message: this.$t(
                "UserManage.adminPage.PleaseSelectManageDepartment"
              ),
              type: "error",
              offset: 100,
            });
          } else {
            this.savaEdit();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async savaEdit() {
      var param = {
        id: this.ruleForm.id,
        userName: this.ruleForm.userName,
        userSex: this.ruleForm.userSex,
        phone: this.ruleForm.phone,
        email: this.ruleForm.email,
        userRole: this.ruleForm.userRole,
        useDeviceNum: this.ruleForm.useDeviceNum,
        manageRange: 0,
        userStatus: 0,
        groupId: this.ruleForm.groupId,
        sendType: this.ruleForm.sendType,
        status: this.ruleForm.status,
        manageGroupId: this.ruleForm.manageGroupId,
        deviceCount: this.ruleForm.deviceCount,
      };
      // console.log(aaa, '编辑保存')
      const res = await this.$axios.post(
        "/httpServe/manage/update",
        param,
        true
      );
      // console.log(res, '管理员编辑')
      // console.log(res, '新增管理员保存')
      if (res.data == -1) {
        this.$message({
          message: this.$t("UserManage.adminPage.UserAlreadyExists"),
          type: "error",
          offset: 100,
        });
      } else if (res.data == -2) {
        this.$message({
          message: this.$t("UserManage.adminPage.AddAdminFailed"),
          type: "error",
          offset: 100,
        });
      } else if (res.data == 0) {
        this.$emit("editSave", "1");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.addAdminForm {
  .el-form-item {
    margin-bottom: 17px !important;
  }
}
.box {
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  padding: 5px 0px;
  width: 250px;
  height: 180px;
  overflow: auto;
}
.show-ellipsis {
  display: block;
  width: 180px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/* 点击树结构项的选中颜色 */
</style>
<style>
.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #ffe6b0;
}
</style>
